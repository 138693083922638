/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'typeface-open-sans';
import 'typeface-raleway';
import 'typeface-fira-code';
import 'prismjs/themes/prism-tomorrow.css';
